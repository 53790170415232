import React from "react"
import { Link } from "gatsby"

// UI
import SEO from "~components/seo"
import Header from "~components/Header/Header"
import Headline from "~ui/Headline"
import SectionFooter from "~components/SectionFooter"

const PrivacyPage: React.FC = () => {
  return (
    <>
      <SEO
        canonicalTag="https://canopyservicing.com/privacy/"
        description="A Canopy, data privacy is a top priority. Our Privacy Policy explains how we collect, use, and share personal information."
        title="Privacy Policy | Canopy Servicing"
      />
      <Header />
      <div className="px-6 py-16 mx-auto max-w-4xl fs-unmask">
        <Headline level={2}>Privacy Policy</Headline>

        <p className="mb-6">
          <strong>Last Updated October 13, 2020</strong>
        </p>
        <p className="mb-6">
          Welcome to the website (the “<strong>Site</strong>”) of Canopy Technology Corp. (“<strong>Canopy</strong>,” “
          <strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>”). Canopy provides a plug and play
          system of record for companies who want to launch lending products quickly and reliably (collectively,
          including the Site, the “<strong>Service</strong>”).
        </p>
        <p className="mb-6">
          This Privacy Policy explains what Personal Information (defined below) we collect, how we use and share that
          information, and your choices concerning our data practices. This Privacy Policy is incorporated into and
          forms part of our <Link to="/terms/">Terms of Service</Link>. Please note that this Privacy Policy does not
          apply to Personal Information collected through means other than the Site or when you otherwise interact with
          us online.
        </p>
        <p className="mb-6">
          Before using the Site or submitting any Personal Information to us, please review this Privacy Policy
          carefully and <a href="mailto:privacy@canopyservicing.com">contact us</a> if you have any questions. By using
          the Site, you agree to the practices described in this Privacy Policy. If you do not agree to this Privacy
          Policy, please do not access the Site or otherwise interact with us online.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          1. Personal Information We Collect
        </Headline>
        <p className="mb-6">
          We collect information that alone or in combination with other information in our possession could be used to
          identify you (“<strong>Personal Information</strong>”) as follows:
        </p>
        <p className="mb-6">
          <strong>Personal Information You Provide</strong>: We collect Personal Information when you complete a form on
          the Site, email us from the Site, sign up to be contacted by us, or otherwise interact with us through the
          Site. The Personal Information collected during these interactions may vary based on what you choose to share
          with us, but it will generally include name, email, phone number, company, and title.
        </p>
        <p className="mb-6">
          <strong>Personal Information We Collect Through Our Social Media Pages</strong>: We have pages on social media
          sites like Twitter and LinkedIn (“Social Media Pages”). When you interact with our Social Media Pages, we will
          collect Personal Information that you elect to provide to us, such as your contact details. In addition, the
          companies that host our Social Media Pages may provide us with aggregate information and analytics regarding
          the use of our Social Media Pages.
        </p>
        <p className="mb-6">
          <strong>Information We Receive Automatically From Your Use of the Site</strong>: When you visit, use, and
          interact with the Site, we may receive certain information about your visit, use, or interactions. For
          example, we may monitor the number of people that visit the Site, peak hours of visits, which page(s) are
          visited, the domains our visitors come from (e.g., google.com, yahoo.com, etc.), and which browsers people use
          to access the Site (e.g., Chrome, Firefox, Microsoft Internet Explorer, etc.), broad geographical information,
          and navigation pattern. In particular, the following information is created and automatically logged in our
          systems:
        </p>
        <ul className="list-disc pl-8">
          <li className="mb-5">
            <strong>Log Data</strong>: Information that your browser automatically sends whenever you visit the Site.
            Log Data includes your Internet Protocol address, browser type and settings, the date and time of your
            request, and how you interacted with the Site.
          </li>
          <li className="mb-5">
            <strong>Cookies Data</strong>: Please see the “Cookies” section below to learn more about how we use
            cookies.
          </li>
          <li className="mb-5">
            <strong>Device Data</strong>: Includes name of the device, operating system, and browser you are using.
            Information collected may depend on the type of device you use and its settings.
          </li>
          <li className="mb-5">
            <strong>Usage Data</strong>: We collect information about how you use the Site, such as the types of content
            that you view or engage with, the features you use, the actions you take, and the time, frequency, and
            duration of your activities.
          </li>
          <li className="mb-5">
            <strong>Email Open/Click Data</strong>: We use pixels in our email campaigns that allow us to collect your
            email and IP address as well as the date and time you open an email or click on any links in the email.
          </li>
        </ul>
        <p className="mb-6">
          <strong>Cookies</strong>: We use cookies to operate and administer our Site, gather usage data on our Site,
          and improve your experience on it. A “cookie” is a piece of information sent to your browser by a website you
          visit. Cookies can be stored on your computer for different periods of time. Some cookies expire after a
          certain amount of time, or upon logging out (session cookies), others survive after your browser is closed
          until a defined expiration date set in the cookie (as determined by the third party placing it), and help
          recognize your computer when you open your browser and browse the Internet again (persistent cookies). For
          more details on cookies please visit{" "}
          <a href="https://www.allaboutcookies.org/" target="_new">
            All About Cookies
          </a>
          .
        </p>
        <p className="mb-6">
          On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for
          information on how to receive a notification when you are receiving a new cookie and how to turn cookies off.
          Please see the links below for guidance on how to modify your web browser’s settings on the most popular
          browsers:
        </p>
        <ul className="list-disc pl-8 mb-5">
          <li>Internet Explorer</li>
          <li>Mozilla Firefox</li>
          <li>Google Chrome</li>
          <li>Apple Safari</li>
        </ul>
        <p className="mb-6">
          Please note that if you limit the ability of websites to set cookies, you may be unable to access certain
          parts of the Site and you may not be able to benefit from the full functionality of the Site.
        </p>
        <p className="mb-6">
          Advertising networks may use cookies to collect Personal Information. Most advertising networks offer you a
          way to opt out of targeted advertising. If you would like to find out more information, please visit the
          Network Advertising Initiative’s online resources at{" "}
          <a href="http://www.networkadvertising.org" target="_new">
            www.networkadvertising.org
          </a>{" "}
          and follow the opt-out instructions there.
        </p>
        <p className="mb-6">
          If you access the Site on your mobile device, you may not be able to control tracking technologies through the
          settings.
        </p>
        <p className="mb-6">
          <strong>Analytics</strong>: We use Google Analytics, a web analytics service provided by Google, Inc.
          (“Google”). Google Analytics uses cookies to help us analyze how users use the Site and enhance your
          experience when you use the Site. For more information on how Google uses this data, go to{" "}
          <a href="https://www.google.com/policies/privacy/partners/" target="_new">
            www.google.com/policies/privacy/partners/
          </a>
          .
        </p>
        <p className="mb-6">
          <strong>Online Tracking and Do Not Track Signals</strong>: We and our third party service providers may use
          cookies or other tracking technologies to collect information about your browsing activities over time and
          across different websites following your use of the Site.
        </p>
        <p className="mb-6">We make reasonable commercial efforts to recognize and support “Do Not Track” signals. </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          2. How We Use Personal Information
        </Headline>
        <p className="mb-6">We may use Personal Information for the following purposes:</p>
        <ul className="list-disc pl-8">
          <li className="mb-5">To respond to your inquiries, comments, feedback, or questions;</li>
          <li className="mb-5">
            To send administrative information to you, for example, information regarding the Site and changes to our
            terms, conditions, and policies;
          </li>
          <li className="mb-5">To analyze how you interact with the Site;</li>
          <li className="mb-5">To maintain and improve the content, functionality, and security of the Site;</li>
          <li className="mb-5">To develop new products and services;</li>
          <li className="mb-5">
            To prevent fraud, criminal activity, or misuses of the Site, and to ensure the security of our IT systems,
            architecture, and networks; and
          </li>
          <li className="mb-5">
            To comply with legal obligations and legal process and to protect our rights, privacy, safety, or property,
            and/or that of our affiliates, you, or other third parties.
          </li>
        </ul>
        <p className="mb-6">
          <strong>Aggregated Information.</strong> We may aggregate Personal Information and use the aggregated
          information to analyze the effectiveness of our Site, to improve and add features to our Site, and for other
          similar purposes. In addition, from time to time, we may analyze the general behavior and characteristics of
          users of our Site and share aggregated information like general user statistics with prospective business
          partners. We may collect aggregated information through the Site, through cookies, and through other means
          described in this Privacy Policy.
        </p>
        <p className="mb-6">
          Marketing. We may use your Personal Information to contact you to tell you about products or services we
          believe may be of interest to you. For instance, if you elect to provide your email or telephone number, we
          may use that information to send you newsletters, special offers, or other information of interest. You may
          opt out of receiving emails by following the instructions contained in each promotional email we send you. In
          addition, if at any time you do not wish to receive future marketing communications, you may{" "}
          <a href="mailto:privacy@canopyservicing.com">contact us</a>. If you unsubscribe from our marketing lists, you
          will no longer receive marketing communications but we will continue to contact you regarding management of
          your account, other administrative matters, and to respond to your requests.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          3. Sharing And Disclosure of Personal Information
        </Headline>
        <p className="mb-6">
          In certain circumstances we may share the categories of Personal Information described above without further
          notice to you, unless required by the law, with the following categories of third parties:
        </p>
        <ul className="list-disc pl-8">
          <li className="mb-5">
            <strong>Vendors and Service Providers</strong>: To assist us in meeting business operations needs and to
            perform certain services and functions, we may share Personal Information with vendors and service
            providers, including providers of hosting services, cloud services, and other information technology
            services, event management services, email communication software and email newsletter services, advertising
            and marketing services, payment processors, customer relationship management and customer support services,
            and web analytics services.
          </li>
          <li className="mb-5">
            <strong>Business Transfers</strong>: If we are involved in a merger, acquisition, financing due diligence,
            reorganization, bankruptcy, receivership, sale of all or a portion of our assets, or transition of service
            to another provider (collectively a “Transaction”), your Personal Information and other information may be
            shared in the diligence process with counterparties and others assisting with the Transaction and
            transferred to a successor or affiliate as part of that Transaction along with other assets.
          </li>
          <li className="mb-5">
            <strong>Legal Requirements</strong>: If required to do so by law or in the good faith belief that such
            action is necessary to (i) comply with a legal obligation, including to meet national security or law
            enforcement requirements, (ii) protect and defend our rights or property, (iii) prevent fraud, (iv) act in
            urgent circumstances to protect the personal safety of users of the Site, or the public, or (v) protect
            against legal liability.
          </li>
          <li className="mb-5">
            <strong>Affiliates</strong>: We may share Personal Information with future affiliates, meaning an entity
            that controls, is controlled by, or is under common control with Canopy. Our affiliates may use the Personal
            Information we share in a manner consistent with this Privacy Policy.
          </li>
        </ul>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          4. Children
        </Headline>
        <p className="mb-6">
          Our Site is not directed to children who are under the age of 16. Canopy does not knowingly collect Personal
          Information from children under the age of 16. If you have reason to believe that a child under the age of 16
          has provided Personal Information to Canopy through the Site please{" "}
          <a href="mailto:privacy@canopyservicing.com">contact us</a> and we will endeavor to delete that information
          from our databases.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          5. Links to Other Websites
        </Headline>
        <p className="mb-6">
          The Site may contain links to other websites not operated or controlled by Canopy, including social media
          services (“<strong>Third Party Sites</strong>”). The information that you share with Third Party Sites will be
          governed by the specific privacy policies and terms of service of the Third Party Sites and not by this
          Privacy Policy. By providing these links we do not imply that we endorse or have reviewed these sites. Please
          contact the Third Party Sites directly for information on their privacy practices and policies.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          6. Security
        </Headline>
        <p className="mb-6">
          You use the Site at your own risk. We implement commercially reasonable technical, administrative, and
          organizational measures to protect Personal Information both online and offline from loss, misuse, and
          unauthorized access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is
          ever fully secure or error free. In particular, e-mail sent to or from us may not be secure. Therefore, you
          should take special care in deciding what information you send to us via the Site or e-mail. Please keep this
          in mind when disclosing any Personal Information via the Internet. In addition, we are not responsible for
          circumvention of any privacy settings or security measures contained on the Site, or third party websites.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          7. International Users
        </Headline>
        <p className="mb-6">
          By using our Site, you understand and acknowledge that your Personal Information will be transferred from your
          location to our facilities and servers in the United States.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          8. Your Choices
        </Headline>
        <p className="mb-6">
          In certain circumstances providing Personal Information is optional. However, if you choose not to provide
          Personal Information that is needed to use some features of our Site, you may be unable to use those features.
          You can also <a href="mailto:privacy@canopyservicing.com">contact us</a> to ask us to update or correct your
          Personal Information.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          9. Changes to the Privacy Policy
        </Headline>
        <p className="mb-6">
          The Site and our business may change from time to time. As a result we may change this Privacy Policy at any
          time. When we do we will post an updated version on this page, unless another type of notice is required by
          the applicable law. By continuing to use our Site or providing us with Personal Information after we have
          posted an updated Privacy Policy, or notified you by other means if applicable, you consent to the revised
          Privacy Policy and practices described in it.
        </p>

        <Headline level={4} className="mb-3 text-lg md:text-2xl pt-6">
          10. Contact Us
        </Headline>
        <p className="mb-6">
          If you have any questions about our Privacy Policy or information practices, please feel free to contact us at{" "}
          <a href="mailto:privacy@canopyservicing.com">privacy@canopyservicing.com</a>.
        </p>
      </div>

      <SectionFooter />
    </>
  )
}

export default PrivacyPage
